import { render, staticRenderFns } from "./TeatroReal.vue?vue&type=template&id=10d8d70d&scoped=true&"
import script from "./TeatroReal.vue?vue&type=script&lang=js&"
export * from "./TeatroReal.vue?vue&type=script&lang=js&"
import style0 from "./TeatroReal.vue?vue&type=style&index=0&id=10d8d70d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d8d70d",
  null
  
)

export default component.exports